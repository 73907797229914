import $ from "jquery";

$(function() {
  $('select[data-option-dependent=true]').each(function (i) {
    const observer_dom_id = $(this).attr('id');
    const observed_dom_id = $(this).data('option-observed');
    const url_mask        = $(this).data('option-url');
    const key_method      = $(this).data('option-key-method');
    const value_method    = $(this).data('option-value-method');
    const prompt          = $(this).find('option').length ? $(this).find('option').first() : $('<option>').text('-');
    const regexp          = /:[0-9a-zA-Z_]+/g;

    const observer = $('select#'+ observer_dom_id);
    const observed = $('select#'+ observed_dom_id);

    observed.on('change', function() {
      observer.empty();
      const url = url_mask.replace(regexp, function() { return observed.val() });
      $.getJSON(url, function(data) {
        observer.append(prompt);
        $.each(data, function(i, object) {
          observer.append($('<option>').attr('value', object[key_method]).text(object[value_method]));
        });
      });
    });
  })
});
