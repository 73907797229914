import $ from "jquery";

$(function(){
  const modal = $('.modal_content');
  const overlay = $('.modal_overlay');

  $('.modal_open').on('click', function() {
    modal.addClass("open");
    overlay.addClass("open");
  });

  $('.modal_close').on('click', function() {
    modal.removeClass("open");
    overlay.removeClass("open");
  });

  overlay.on('click', function() {
    modal.removeClass("open");
    overlay.removeClass("open");
  });
});
