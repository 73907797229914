// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import $ from "jquery"
import "slick-carousel"
import "./cascading-dropdown"
import "./modal"
import Cookies from "js-cookie"

Rails.start()
ActiveStorage.start()

$(function() {
  $(".p-univ__list.top").slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay : true,
    prevArrow: "<div class='p-univ__prev p-univ__btn'></div>",
    nextArrow: "<div class='p-univ__next p-univ__btn'></div>",
    responsive: [
      {
        breakpoint: 896,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  })

  $(".recommended_jobs").slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay : true,
    prevArrow: "<div class='p-univ__prev p-univ__btn'></div>",
    nextArrow: "<div class='p-univ__next p-univ__btn'></div>",
    responsive: [
      {
        breakpoint: 896,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  })

  $('.banner-close').on('click', function() {
    $(this).parent().hide();
    Cookies.set("line_banner", "close", {expires: 1});
  })

  $(window).on("scroll", function() {
    if (Cookies.get("line_banner") !== "close") {
      if ($(this).scrollTop() > 200) {
          $(".banner").fadeIn(300)
      }
    }
  })

  $(window).on('scroll load', function() {
    if ($(this).scrollTop() > 0) {
      $('.l-header').addClass('is-scroll');
    } else {
      $('.l-header').removeClass('is-scroll');
    }
  });

  $('.p-header-toggle').on('click', function() {
    $(".p-hnav").toggleClass("is-active");
    $("body").toggleClass("is-active");
  });
})
